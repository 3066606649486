<template>
  <div class="">
    <a-modal :visible="visible"
             :footer="null" @change="onChange"
             :closable="true"
             :keyboard="true"
             :width="650"
    >
      <h2 class="text-primary-500">
        <!--        <a-icon type="monitor"/>-->
        Theo dõi Shop mới
      </h2>
      <h2 class="atitle" style="white-space: nowrap">
        <a-icon type="link"/>
        URL Shop
      </h2>
      <a-input
        v-model="shopUrl"
        v-decorator="['note', { rules: [{ required: true, message: 'Cần nhập URL Shop' }] }]"
        class="mb-4"
        placeholder="Nhập đường dẫn của shop (VD: https://shopee.vn/apple_flagship_store)"
        :allowClear="true"
      />

      <a-row type="flex" justify="end" :gutter="8">
        <a-col>
          <a-button type="default" size="large" @click="onCancel">
            Hủy
          </a-button>
        </a-col>

        <a-col>
          <a-button type="primary" :icon="isLoading ? 'loading' : 'plus'" size="large" @click="onSubmit">
            Thêm Shop
          </a-button>
        </a-col>
      </a-row>

    </a-modal>
  </div>
</template>
<script>
import { createShopSubscribe } from '../../service/market/ShopSubscribeService'

export default {
  props: {
    visible: true
  },
  data() {
    return {
      shopUrl: null,
      isLoading: false
    }
  },
  methods: {
    onChange(visible) {
      //ẩn hiện popup
      this.$emit('change', visible)
    },
    onCancel() {
      this.onChange(false)
    },
    async onSubmit() {
      this.isLoading = true
      let response = await createShopSubscribe(this.shopUrl)
      this.isLoading = false
      if (response?.['status'] === 'error') {
        let message = response['message_vi']
        console.log(message)
        this.$notification['error']({
          message: 'Không thêm được Shop theo dõi',
          description: message,
        })
      }
      if (response?.['status'] === 'success') {
        console.log(response)
        this.$emit('change', false)
        this.$emit('submit')
      }

    }
  }
}
</script>

<style lang="scss">

</style>
