<template>
  <div>
    <MobileDefault v-if="isMobile">
      <div class="container-bg-white container-space-mobile-default mobile-view">
        <h1 class="title">
          <span class="border-left-line"></span>Danh sách Shop đang theo dõi
        </h1>
        <div class="mt-4 text-primary-500" v-if="permissionAvailableName === PERMISSION.market_staff.name">
          <span class="font-medium underline cursor-pointer" @click="isShowCheckShopModal = true">Kiểm tra số liệu Shop</span>
          <a-tooltip placement="top" class="cursor-pointer">
            <template slot="title">
              Kiểm tra độ chính xác của số liệu thực tế và số liệu Metric cung cấp
            </template>
            <a-icon
              type="info-circle"
              style="transform: translateY(-6px);"
            />
          </a-tooltip>
        </div>
        <div class="flex justify-space-between items-center">
          <a-button class="my-4 text-xs" style="height: 30px; width: 157px" type="primary" size="large" icon="plus" @click="onClickCreateShopSubscribe" :disabled="lstShopSubscribe.length >= limitShopSubscribe">
            Theo dõi Shop mới
          </a-button>
          <a-select v-model="chosenRange"  style="height: 30px; width: 100px">
            <a-select-option value="7">7 Ngày</a-select-option>
            <a-select-option value="30">30 Ngày</a-select-option>
            <a-select-option value="90">90 Ngày</a-select-option>
          </a-select>
        </div>
        <p v-if="lstShopSubscribe.length >= limitShopSubscribe" style="color: #ee0033">Bạn đã hết lượt theo dõi shop ({{ limitShopSubscribe }}/{{ limitShopSubscribe }})</p>
        <div>
          <a-checkbox v-model="isEnableFilterAbnormal" @change="onChangeIsEnableFilterAbnormal">Lọc sản phẩm ảo/bất thường</a-checkbox>
        </div>
        <a-table
          :columns="localColumns"
          :tableLayout="isMobile ? 'fixed' : ''"
          :rowKey="record => record.shop_base_id"
          :dataSource="lstShopSubscribe"
          :pagination="pagination"
          :loading="isLoading"
          @change="handleTableChange"
          class="shop-subscribe"
        >
          <!--        <span slot="shop_name">Shop</span>-->

          <!--        <span slot="platform">Nơi bán</span>-->

          <template slot="shop_name" slot-scope="shop_name, shop">
            <a-row type="flex" align="middle" class="my-2">
              <div>
                <div class="font-medium text-left">{{ shop_name }}</div>
              </div>
            </a-row>

            <a-row type="flex" :gutter="16" class="my-2">
              <a-col>
                <img :src="getPlatformById(shop.platform_id).urlLogo" :alt="getPlatformById(shop.platform_id).name" class="img-platform mr-1" style="margin-bottom: 4px">
                <span class="p-0 text-primary-500 cursor-pointer" @click="onClickGoToLinkShop(shop)">
                  Link Shop
                </span>
              </a-col>
            </a-row>
          </template>

          <template slot="platform" slot-scope="platform, shop">
            <a-row type="flex" align="middle">
              <img :src="getPlatformById(shop.platform_id).urlLogo" :alt="getPlatformById(shop.platform_id).name" class="img-platform mr-2">
            </a-row>
          </template>

          <template slot="product_total" slot-scope="number">
            {{ number | formatNumberHuman }}
          </template>

          <template slot="order_count_7d" slot-scope="number, shop">
            <p>{{ number | formatNumberHuman }}</p>
            <p v-if="shop.order_count_7d_previous"><CompareValue :value="number" :value-comparison="shop.order_count_7d_previous"/> so với cùng kì</p>
          </template>

          <template slot="order_revenue_7d" slot-scope="number, shop">
            <p>{{ number | formatCurrency }}</p>
            <p v-if="shop.order_revenue_7d_previous"><CompareValue :value="number" :value-comparison="shop.order_revenue_7d_previous"/> so với cùng kì</p>
          </template>

          <template slot="order_count_30d" slot-scope="number, shop">
            <p>{{ number | formatNumberHuman }}</p>
            <p v-if="shop.order_count_30d_previous"><CompareValue :value="number" :value-comparison="shop.order_count_30d_previous"/> so với cùng kì</p>
          </template>

          <template slot="order_revenue_30d" slot-scope="number, shop">
            <p>{{ number | formatCurrency }}</p>
            <p v-if="shop.order_revenue_30d_previous"><CompareValue :value="number" :value-comparison="shop.order_revenue_30d_previous"/> so với cùng kì</p>
          </template>

          <template slot="order_count_90d" slot-scope="number, shop">
            <p>{{ number | formatNumberHuman }}</p>
            <p v-if="shop.order_count_90d_previous"><CompareValue :value="number" :value-comparison="shop.order_count_90d_previous"/> so với cùng kì</p>
          </template>

          <template slot="order_revenue_90d" slot-scope="number, shop">
            <p>{{ number | formatCurrency }}</p>
            <p v-if="shop.order_revenue_90d_previous"><CompareValue :value="number" :value-comparison="shop.order_revenue_90d_previous"/> so với cùng kì</p>
          </template>

          <template slot="action" slot-scope="action, shop">
            <div class="mx-auto px-2 py-1 border border-gray-400 text-gray-400 rounded cursor-pointer" style="width: 40px; height: 20px; font-size: 8px" @click="onDeleteShop(shop)">
              Xóa
            </div>
          </template>
        </a-table>

        <!--      <p v-if="lstShopSubscribe == null || lstShopSubscribe.length === 0">Hiện chưa có Shop đang theo dõi</p>-->

        <ModalSubscribeShop :visible="isShowModalSubscribeShop" @change="onChangeModalSubscribeShop"
                            @submit="onSubmitModalSubscribeShop"/>

      </div>
    </MobileDefault>
    <Default v-else class="subscribe-index">
      <div class="container-bg-white container-space-default">
        <h1 class="title"><span class="border-left-line"></span>Danh sách Shop đang theo dõi
        </h1>
        <div class="mt-4 text-primary-500" v-if="permissionAvailableName === PERMISSION.market_staff.name">
          <span class="font-medium underline text-base cursor-pointer" @click="isShowCheckShopModal = true">Kiểm tra số liệu Shop</span>
          <a-tooltip placement="topLeft" class="cursor-pointer">
            <template slot="title">
              Kiểm tra độ chính xác của số liệu thực tế và số liệu Metric cung cấp
            </template>
            <a-icon
              type="info-circle"
              style="transform: translateY(-6px);"
            />
          </a-tooltip>
        </div>
        <a-row type="flex" justify="end" align="top" class="mb-5">
          <a-col>
            <a-button type="primary" size="large" icon="plus" @click="onClickCreateShopSubscribe" :disabled="lstShopSubscribe.length >= limitShopSubscribe">
              Theo dõi Shop mới
            </a-button>
            <p v-if="lstShopSubscribe.length >= limitShopSubscribe" style="color: #ee0033">Bạn đã hết lượt theo dõi shop ({{ limitShopSubscribe }}/{{ limitShopSubscribe }})</p>
          </a-col>
        </a-row>

        <a-row type="flex" justify="end" class="mb-3">
          <a-checkbox v-model="isEnableFilterAbnormal" @change="onChangeIsEnableFilterAbnormal">Lọc sản phẩm ảo/bất
            thường
          </a-checkbox>
        </a-row>

        <a-table
          :columns="localColumns"
          :rowKey="record => record.shop_base_id"
          :dataSource="lstShopSubscribe"
          :pagination="pagination"
          :loading="isLoading"
          @change="handleTableChange"
          class="shop-subscribe"
        >
          <!--        <span slot="shop_name">Shop</span>-->

          <!--        <span slot="platform">Nơi bán</span>-->

          <template slot="shop_name" slot-scope="shop_name, shop">
            <a-row type="flex" align="middle">
              <img :src="shop.shop_url_image" :alt="shop.shop_name" class="img-shop mr-2">
              <div>
                <div class="font-medium">{{ shop_name }}</div>
              </div>
            </a-row>

            <a-row type="flex" :gutter="16">
              <a-col>
                <a-button class="p-0" @click="onClickGoToAnalyticShop(shop)" type="link">
                  <a-icon type="stock"/>
                  Phân tích
                </a-button>
              </a-col>
              <a-col>
                <a-button class="p-0" type="link" @click="onClickGoToLinkShop(shop)">
                  <a-icon type="shop"/>
                  Link Shop
                </a-button>
              </a-col>
            </a-row>
          </template>

          <template slot="platform" slot-scope="platform, shop">
            <a-row type="flex" align="middle">
              <img :src="getPlatformById(shop.platform_id).urlLogo" :alt="getPlatformById(shop.platform_id).name" class="img-platform mr-2">
            </a-row>
          </template>

          <template slot="product_total" slot-scope="number">
            {{ number | formatNumberHuman }}
          </template>

          <template slot="order_count_7d" slot-scope="number, shop">
            <p>{{ number | formatNumberHuman }}</p>
            <p v-if="shop.order_count_7d_previous"><CompareValue :value="number" :value-comparison="shop.order_count_7d_previous"/> so với cùng kì</p>
          </template>

          <template slot="order_revenue_7d" slot-scope="number, shop">
            <p>{{ number | formatCurrency }}</p>
            <p v-if="shop.order_revenue_7d_previous"><CompareValue :value="number" :value-comparison="shop.order_revenue_7d_previous"/> so với cùng kì</p>
          </template>

          <template slot="order_count_30d" slot-scope="number, shop">
            <p>{{ number | formatNumberHuman }}</p>
            <p v-if="shop.order_count_30d_previous"><CompareValue :value="number" :value-comparison="shop.order_count_30d_previous"/> so với cùng kì</p>
          </template>

          <template slot="order_revenue_30d" slot-scope="number, shop">
            <p>{{ number | formatCurrency }}</p>
            <p v-if="shop.order_revenue_30d_previous"><CompareValue :value="number" :value-comparison="shop.order_revenue_30d_previous"/> so với cùng kì</p>
          </template>

          <template slot="order_count_90d" slot-scope="number, shop">
            <p>{{ number | formatNumberHuman }}</p>
            <p v-if="shop.order_count_90d_previous"><CompareValue :value="number" :value-comparison="shop.order_count_90d_previous"/> so với cùng kì</p>
          </template>

          <template slot="order_revenue_90d" slot-scope="number, shop">
            <p>{{ number | formatCurrency }}</p>
            <p v-if="shop.order_revenue_90d_previous"><CompareValue :value="number" :value-comparison="shop.order_revenue_90d_previous"/> so với cùng kì</p>
          </template>

          <template slot="action" slot-scope="action, shop">
            <a-row type="flex" :gutter="8">
              <a-col>
                <a-button type="danger" :ghost=true @click="onDeleteShop(shop)">
                  Xóa
                </a-button>
              </a-col>
            </a-row>
          </template>
        </a-table>

        <!--      <p v-if="lstShopSubscribe == null || lstShopSubscribe.length === 0">Hiện chưa có Shop đang theo dõi</p>-->

        <ModalSubscribeShop :visible="isShowModalSubscribeShop" @change="onChangeModalSubscribeShop"
                            @submit="onSubmitModalSubscribeShop"/>

      </div>
    </Default>
    <ModalCheckShop :visible="isShowCheckShopModal" @change="isShowCheckShopModal = false" />
  </div>
</template>

<script>
import { deleteShopSubscribe, getListShopSubscribe } from '../../service/market/ShopSubscribeService'
import Default from '../../layout/Default'
import { getPlatformById } from '../../service/platform/PlatformCommonService'
import ModalSubscribeShop from '../../components/subscribe/ModalSubscribeShop'
import { MutationGeneral } from '../../store/modules/GeneralModule/mutation'
import { MutationSidebar } from '../../store/modules/SidebarModule/mutation'
import { ActionNameConstants } from '../../constant/general/GeneralConstant'
import { formatCurrency, formatNumberHuman } from '../../helper/FormatHelper'
import {mixinStoreGetterPermission} from "@/mixin/store/MixinUser";
import {PERMISSION} from "@/constant/general/PermissionConstant";
import {generateColumnsShopTable} from "@/service/market/MarketService";
import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";
import MobileDefault from "@/layout/MobileDefault";
import CompareValue from "@/components/common/CompareValue";
import ModalCheckShop from "../../components/subscribe/ModalCheckShop";

export default {
  components: {
    Default,
    MobileDefault,
    ModalSubscribeShop,
    ModalCheckShop,
    CompareValue,
  },
  data() {
    let chosenRange = null
    return {
      isEnableFilterAbnormal: false,
      isShowModalSubscribeShop: false,
      lstShopSubscribe: [],
      pagination: {
        'current': 0,
        'pageSize': 50,
        // 'total': 200,
        'simple': true,
      },
      isLoading: true,
      chosenRange: chosenRange,
      isShowCheckShopModal: false,
      PERMISSION,
    }
  },
  async mounted() {
    this.chosenRange = this.isMobile ? '7' : null
    await this.onLoadListShopSubscribe()
    console.log('Số shop tối đa có thể theo dõi (mỗi user) là ' ,this.limitShopSubscribe)
  },
  created() {
    this.$store.commit(`GeneralModule/${MutationGeneral.setActionName}`, ActionNameConstants.SUBSCRIBE)
    this.$store.commit(`SidebarModule/${MutationSidebar.menuSelected}`, ActionNameConstants.SUBSCRIBE)
  },
  methods: {
    async onChangeIsEnableFilterAbnormal(value) {
      // this.onChangeIsEnableFilterAbnormal = value
      await this.onLoadListShopSubscribe()
    },
    onClickCreateShopSubscribe() {
      this.isShowModalSubscribeShop = true
    },
    onChangeModalSubscribeShop(visible) {
      this.isShowModalSubscribeShop = visible
    },
    async onSubmitModalSubscribeShop() {
      await this.onLoadListShopSubscribe()
    },
    async onLoadListShopSubscribe() {
      this.isLoading = true
      let options = {
        'filter': {
          'is_filter_abnormal': this.isEnableFilterAbnormal
        }
      }

      let responseLstSubscribe = await getListShopSubscribe(options)
      this.lstShopSubscribe = responseLstSubscribe?.['data']?.['lst_shop_subscribe'].splice(0, this.limitShopSubscribe)
      this.isLoading = false
      // console.log('reload shops', this.lstShopSubscribe)
    },
    async onDeleteShop(shop) {
      let that = this
      this.$confirm({
        title: `Bạn có muốn bỏ theo dõi Shop?`,
        content: `Đồng ý bỏ theo dõi Shop: ${shop?.['shop_name']}`,
        okText: 'Bỏ theo dõi',
        cancelText: 'Hủy',
        async onOk() {
          await deleteShopSubscribe(shop?.['shop_url'])
          await that.onLoadListShopSubscribe()
        },
        onCancel() {

        },
      })
    },
    async handleTableChange(pagination, filters, sorter) {
      this.isLoading = true
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      if (sorter != null && Object.keys(sorter).length > 0) {
        let sort = sorter?.['field']
        if (sorter?.['field'] === 'category_name') {
          sort = 'categories.name'
        }
        if (sorter['order'] === 'ascend') {
          sort += `__asc`
        } else if (sorter['order'] === 'descend') {
          sort += `__desc`
        } else {
          this.isLoading = false
          return
        }
        this.lstShopSubscribe = this.lstShopSubscribe.sort((shop1, shop2) => (shop1[sorter.field] - shop2[sorter.field]) * (sorter['order'] === 'ascend' ? 1 : -1))
      }
      this.isLoading = false
    },
    onClickGoToLinkShop(shop) {
      let shopUrl = shop?.['shop_url']
      window.open(shopUrl, '_blank')
    },
    onClickGoToAnalyticShop(shop) {
      let shopUrl = shop?.['shop_url']
      let baseUrl = this.$router?.options?.base
      let url = `${baseUrl}?shop_url=${shopUrl}`
      // console.log(url)
      window.open(url, '_blank')
    },
    getPlatformById,
  },
  computed: {
    limitShopSubscribe(){
      return PERMISSION[this.permissionAvailableName]?.limitShopSubscribe || 0
    },
    localColumns() {
      let listField = this.isMobile
        ? [
          "shop_name",
          "product_total",
          `order_count_${this.chosenRange}d`,
          `order_revenue_${this.chosenRange}d`,
          "action"
        ]
        : [
          "shop_name",
          "platform",
          "product_total",
          "order_count_30d",
          "order_revenue_30d",
          "order_count_90d",
          "order_revenue_90d",
          "action"
        ]
      return generateColumnsShopTable().filter(item => listField.includes(item.dataIndex))
    }
  },
  filters: {
    formatCurrency: formatCurrency,
    formatNumberHuman: formatNumberHuman,
  },
  mixins: [
    mixinStoreGetterGlobal,
    mixinStoreGetterPermission
  ],
  watch: {
    permissionAvailableName() {
      this.onLoadListShopSubscribe()
    }
  }
}
</script>

<style lang="scss" scoped>
.shop-subscribe {
  .img-shop {
    width: 30px;
    height: 30px;
    object-fit: cover;
  }

  .img-platform {
    width: 25px;
    height: 25px;
    border-radius: 9999px;
    object-fit: cover;
  }
}

.mobile-view {

  h1.title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
  }

  .border-left-line {
    border-left-width: 1px;
    padding-right: 6px;
    font-size: 19px;
  }

  .img-platform {
    width: 10px;
    height: 10px;
    border-radius: 9999px;
    object-fit: cover;
  }

  /deep/ .ant-table {
  }

  /deep/ .ant-table-thead > tr > th {
    font-size: 10px !important;
    padding: 2px 6px !important;
    font-weight: 400;
    background: unset;
  }

  /deep/ .ant-table-tbody > tr > td {
    font-size: 8px !important;
    padding: 2px !important;
    text-align: center;
  }

}
</style>
