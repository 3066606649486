<template>
  <a-modal
    :visible="visible"
    :footer="null"
    @change="onChange"
    :closable="true"
    :keyboard="true"
    :width="650"
    id="modalCheckShop"
  >
    <div :class="hasData ? 'px-6 pt-6' : 'px-6 pt-6 pb-2'">
      <h3 class="modal-title text-primary-500">
        Kiểm tra độ chính xác số liệu Shop
      </h3>
      <h2 class="label whitespace-nowrap">
        <a-icon type="link" />
        URL Shop
      </h2>
      <a-input
        v-model="shopUrl"
        v-decorator="[
          'note',
          { rules: [{ required: true, message: 'Cần nhập URL Shop' }] },
        ]"
        class="mb-4 shop-url-input"
        placeholder="Nhập đường dẫn của shop"
        :allowClear="true"
        :disabled="isLoading"
        :pressEnter="onSubmit"
      />
      <div v-if="isLoading && isShowLoadingNote" style="color: #2368d4">
        Bạn vui lòng chờ chút nhé!
      </div>
      <a-row type="flex" justify="end" :gutter="8" class="mb-4">
        <a-col>
          <a-button
            type="primary"
            :icon="isLoading ? 'loading' : ''"
            @click="onSubmit"
            :disabled="isLoading || (prevShopUrl && shopUrl === prevShopUrl)"
          >
            Kiểm tra
          </a-button>
        </a-col>
      </a-row>
      <div class="check-shop-result" v-if="hasData">
        <div v-if="data.shop_name">
          Tên Shop: <span class="text-primary-500">{{ data.shop_name }}</span>
        </div>
        <a-row type="flex" justify="center" class="total-order-rate-life py-6">
          <h1 class="total-order-rate-life-title">Độ chính xác số liệu Shop</h1>
          <a-progress
            :percent="data.statistic.total_order_rate_life"
            :showInfo="false"
            :class="progressClass"
            :strokeWidth="20"
          />
          <div class="text-center">
            <span
              class="total-order-rate-life-value"
              :class="`${progressClass}-value`"
              >{{ parseInt(data.statistic.total_order_rate_life, 10) }}</span
            >/100%
          </div>
        </a-row>
        <a-row
          type="flex"
          justify="space-between"
          class="mb-3 flex-nowrap w-full items-end"
        >
          <div>
            <div
              class="text-sm md:text-base font-medium mb-2 line-clamp__2 mr-4"
            >
              Bảng so sánh chỉ số
              <span v-if="data.shop_name">{{ data.shop_name }}</span>
            </div>
            <div class="text-xs md:text-sm">
              Số sản phẩm: {{ data.products && data.products.length }}
            </div>
          </div>
          <div>
            <a-button
              class="relative"
              type="primary"
              size="large"
              @click="onDownloadProductsCSV"
              ghost
              :disabled="isDownloadingSomething"
            >
              <a-icon type="download" />
              <span v-if="isDesktop">Download danh sách</span>
            </a-button>
          </div>
        </a-row>
      </div>
    </div>
    <div :class="isDesktop ? 'px-6' : ''" v-if="hasData">
      <a-table
        :columns="columns"
        :data-source="data.products"
        :pagination="
          isMobile
            ? {
                pageSize: 10,
                current: currentPage,
                total: data.products.length,
              }
            : {
                simple: true,
                pageSize: 10,
                defaultCurrent: currentPage,
                total: data.products.length,
              }
        "
      >
        <div slot="name" slot-scope="item">
          <div class="flex mb-2">
            <div
              class="mr-2 flex items-center"
              v-if="item.url_thumbnail && isDesktop"
            >
              <img :src="item.url_thumbnail" class="mt-1 product-thumbnail" />
            </div>
            <p
              :title="item.name"
              class="text-sm text-black"
              :class="isDesktop ? 'line-clamp__3' : 'line-clamp__2'"
            >
              {{ item.name }}
            </p>
          </div>
          <a
            :href="getUrlProductDirect(item.product_base_id)"
            target="_blank"
            class="product-link"
          >
            Link sản phẩm
          </a>
        </div>
        <div
          slot="order_count_life_rate"
          slot-scope="order_count_life_rate"
          v-if="order_count_life_rate"
        >
          {{ parseInt(order_count_life_rate, 10) }}%
        </div>
      </a-table>

      <Pagination
        :current-page="currentPage"
        :total-page="totalPage"
        v-if="isMobile"
        class="container-bg-white px-2 py-3 text-sm"
        style="color: #8c8c8c; font-size: 12px"
        @change="onChangePage"
      />
    </div>
  </a-modal>
</template>
<script>
import { getUrlProductDirect } from "@/service/product/UrlProductService";
import { getMarketShopMonitor } from "@/service/market/ShopSubscribeService";
import { mixinStoreGetterGlobal } from "@/mixin/store/MixinGlobal";
import Pagination from "@/components/common/Pagination";

const columns = [
  {
    title: "Tên sản phẩm",
    dataIndex: "",
    key: "name",
    width: "40%",
    align: "left",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "Số đơn bán (Thực tế)",
    dataIndex: "total_order_count_life",
    key: "total_order_count_life",
    width: "18%",
    align: "center",
  },
  {
    title: "Số đơn bán (Metric)",
    dataIndex: "bee_total_order_count_life",
    key: "bee_total_order_count_life",
    width: "18%",
    align: "center",
  },
  {
    title: "Độ chính xác",
    dataIndex: "order_count_life_rate",
    key: "order_count_life_rate",
    width: "16%",
    align: "center",
    scopedSlots: { customRender: "order_count_life_rate" },
  },
];

const columnsWidthMobile = {
  name: "30%",
  total_order_count_life: "25%",
  bee_total_order_count_life: "25%",
  order_count_life_rate: "21%",
};

const getDate = () => {
  const date = new Date();
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

export default {
  props: {
    visible: true,
  },
  components: { Pagination },
  data() {
    return {
      shopUrl: "",
      prevShopUrl: null,
      isLoading: false,
      isShowLoadingNote: false,
      data: {},
      columns,
      currentPage: 1,
      totalPage: 1,
    };
  },
  mounted() {
    this.columns = this.isDesktop
      ? columns
      : columns.map((item) => ({
          ...item,
          width: columnsWidthMobile[item.dataIndex],
        }));
  },
  mixins: [mixinStoreGetterGlobal],
  computed: {
    isTotalOrderRateLow() {
      return (
        this.data.statistic && this.data.statistic.total_order_rate_life < 50
      );
    },
    progressClass() {
      if (
        this.data.statistic &&
        this.data.statistic.total_order_rate_life < 50
      ) {
        return "low";
      }
      if (
        this.data.statistic &&
        this.data.statistic.total_order_rate_life > 50 &&
        this.data.statistic.total_order_rate_life < 80
      ) {
        return "medium";
      }
      return "high";
    },
    hasData() {
      return Object.keys(this.data).length;
    },
  },
  methods: {
    getUrlProductDirect,
    onChange(visible) {
      //ẩn hiện popup
      this.$emit("change", visible);
    },
    onCancel() {
      this.onChange(false);
    },
    onDownloadProductsCSV() {
      const keys = [
        "name",
        "product_url",
        "total_order_count_life",
        "bee_total_order_count_life",
        "order_count_life_rate",
      ];
      const titles = [
        "Tên sản phẩm",
        "Link sản phẩm",
        "Số đơn bán (Thực tế)",
        "Số đơn bán (Metric)",
        "Độ chính xác",
      ];
      let csv = titles.join(",") + "\n";
      this.data.products.forEach((product, index) => {
        csv += keys
          .map((key) => {
            if (key === "order_count_life_rate") {
              return (product[key] || "") + "%";
            }
            return '"' + (product[key] || "") + '"';
          })
          .join(",");
        csv += "\n";
      });

      const link = document.createElement("a");
      link.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
      link.target = "_blank";

      const date = getDate();
      if (this.data.shop_name) {
        link.download =
          "download danh sach kiem tra so lieu shop " +
          this.data.shop_name +
          " " +
          date +
          ".csv";
      } else {
        link.download =
          "download danh sach kiem tra so lieu shop" + date + ".csv";
      }
      link.click();
    },
    async onSubmit() {
      if (!this.shopUrl) {
        this.isLoading = false;
        return;
      }
      this.data = {};
      this.isLoading = true;
      const timer = setTimeout(() => {
        this.isShowLoadingNote = true;
      }, 5000);
      const response = await getMarketShopMonitor(this.shopUrl);
      clearTimeout(timer);
      this.isLoading = false;
      this.isShowLoadingNote = false;
      if (!response) {
        this.$message.error("Shop không tồn tại");
        return;
      }

      this.data = {
        ...response,
        products: response.products.map((product) => ({
          ...product,
          product_url: getUrlProductDirect(product.product_base_id),
        })),
      };
      this.totalPage = Math.ceil(this.data.products.length / 10);
    },
    onChangePage(page) {
      if (page > this.totalPage) {
        page = this.totalPage;
      }
      if (page < 1) {
        page = 1;
      }
      this.currentPage = page;
    },
  },
};
</script>

<style lang="scss">
#modalCheckShop {
  .ant-modal-body {
    padding: 0;
  }
  .ant-table-pagination {
    display: none;
  }
  .total-order-rate-life {
    @apply text-black text-lg;
    max-width: 300px;
    margin: auto;

    .total-order-rate-life-value {
      font-size: 32px;
      font-weight: 800;
      line-height: 38px;

      &.low-value {
        color: #ff7227;
      }
      &.medium-value {
        color: #ffc634;
      }
      &.high-value {
        color: #00c259;
      }
    }
  }
  .ant-progress.low {
    .ant-progress-outer {
      border: 5px solid #f4f9f8;
      border-radius: 80px;

      .ant-progress-inner {
        background: rgba(255, 114, 39, 0.2);
      }
      .ant-progress-bg {
        background: linear-gradient(
          90deg,
          rgba(255, 114, 39, 0.45) 0%,
          #ff7227 100%
        );
        border: 2px solid #ff7227;
      }
    }
  }
  .ant-progress.medium {
    .ant-progress-outer {
      border: 5px solid #f4f9f8;
      border-radius: 80px;

      .ant-progress-inner {
        background: rgba(255, 198, 52, 0.2);
      }
      .ant-progress-bg {
        background: linear-gradient(
          90deg,
          rgba(255, 198, 52, 0.5) 0%,
          #ffc634 100%
        );
        border: 2px solid #ffc634;
      }
    }
  }
  .ant-progress.high {
    .ant-progress-outer {
      border: 5px solid #f4f9f8;
      border-radius: 80px;

      .ant-progress-inner {
        background: rgba(0, 194, 89, 0.2);
      }
      .ant-progress-bg {
        background: linear-gradient(
          90deg,
          rgba(3, 189, 55, 0.45) 0%,
          #03bd37 100%
        );
        border: 2px solid #00c259;
      }
    }
  }
  .product-thumbnail {
    width: 52px;
    border-radius: 2px;
  }

  .modal-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }
  .label {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
  .total-order-rate-life-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }
  .shop-url-input {
    .ant-input {
      font-style: italic;
    }
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 8px 8px;
  }
  .ant-table-thead > tr > th .ant-table-header-column {
    font-weight: 400;
  }
  .product-link {
    font-size: 13px;
  }
  @media (min-width: 576px) {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      padding: 16px 16px;
    }
    .modal-title {
      font-size: 22px;
      line-height: 26px;
    }
    .ant-table-pagination {
      display: block;
    }
    .ant-table-thead > tr > th .ant-table-header-column {
      font-weight: 500;
    }
    .label {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }
    .total-order-rate-life-title {
      font-size: 22px;
      line-height: 26px;
    }
    .total-order-rate-life {
      .total-order-rate-life-value {
        font-size: 48px;
        line-height: 56px;
      }
    }
    .shop-url-input {
      .ant-input {
        font-style: normal;
      }
    }
    .product-link {
      font-size: 14px;
    }
  }
}
</style>
